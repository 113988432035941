import styled from "styled-components";

export const Container = styled.div`
  padding-top: 100px;

  .call-us {
    position: relative;
    left: -25px;
    padding-left: -25px;
    @media only screen and (max-width: 768px) {
    }
  }
  @media only screen and (max-width: 561px) {
    padding-top: 50px;
  }
  @media only screen and (min-width: 562px) and (max-width: 768px) {
    padding-top: 100px;
  }
  @media only screen and (min-width: 769px) and (max-width: 1000px) {
    padding-top: 200px;
  }
`;

export const Content = styled.div`
  @media only screen and (max-width: 768px) {
    margin-top: 20px;

    p {
      max-width: 80%;
      margin: 0px auto;
    }
  }
`;

export const FormContainer = styled.div`
  position: relative;
  left: -60px;

  @media only screen and (max-width: 768px) {
    left: 0px;
    margin: 20px 0px;
  }
`;
