import React from "react";
import {
  Container,
} from "../styled";
import { defaultFormValues } from "../../../../Franchise/FranchiseForm/_form";
import useForm from "../../../../../hooks/useForm";
import FormConsultation from "../../../../Forms/Consultation";

const LargeForm = () => {
  const { formValues, loading, setEmailErr, handleChange, handleSubmit } =
    useForm({ defaultFormValues });

  return (
    <Container>
      <FormConsultation variant={"large"} />
    </Container>
  );
};

export default LargeForm;
