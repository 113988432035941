import { useState, useEffect } from "react";
import Typography from "../../../../components/Typography";
import LargeForm from "./LargeForm";
import SmallForm from "./SmallForm";
import { ScheduleFormContainer } from "./styled";

export default function ScheduleForm({ variant, title, padding, submitText, subTitle }) {
  const variants = ["small", "large"];
  variant = variants.includes(variant) ? variant : variants[0];
  const [showSmall, setShowSmall] = useState(true);

  useEffect(() => {
    setShowSmall(variant === "small");
  }, [variant]);

  return (
    <ScheduleFormContainer padding={padding}>
      {title && (
        <Typography
          fontFamily="Roboto Bold"
          color="white"
          fontSize="1.2rem"
          textAlign="center"
          padding={0}
          margin={0}
          style={{ letterSpacing: "1px" }}
        >
          {title}
        </Typography>
      )}
      {subTitle && (
        <Typography
          fontFamily="Roboto"
          color="white"
          fontSize=".8rem"
          textAlign="center"
          padding={0}
          style={{ letterSpacing: ".5px", paddingBottom: ".5rem" }}
        >
          {subTitle}
        </Typography>
      )}
      {showSmall && <SmallForm submitText={submitText} />}
      {!showSmall && <LargeForm />}
    </ScheduleFormContainer>
  );
}
