import React, { useState } from "react";
import {
  Container,
} from "../styled";
import FormConsultation from "../../../../Forms/Consultation";

const SmallForm = ({ submitText }) => {

  return (
    <>
      <Container>
        <FormConsultation />
      </Container>
    </>
  );
};

export default SmallForm;
