import { Col, Row } from "antd";
import Image from "next/image";
import Typography from "../../../components/Typography";
import ScheduleForm from "../../CabinetsPage/Schedule/ScheduleForm";
import { Container, Content, FormContainer } from "./styled";

export default function GetStarted({ formId }) {
  return (
    <Container>
      <Row align="middle" gutter={32}>
        <Col xs={24} md={14}>
          <Image
            src="/images/kitchen-home/get-started-new.jpg"
            alt="designer standing next to custom home media center"
            layout="responsive"
            width="1146"
            height="765"
            quality={50}
          />
        </Col>
        <Col xs={24} md={8}>
          <Content>
            <FormContainer>
              <ScheduleForm
                subTitle={`A professional designer in your area will contact you for a FREE
                consultation.`}
                title={"Ready to get started?"}
                formId={formId}
                submitText="GET STARTED"
              />
            </FormContainer>

            <span className="call-us">
              <Typography
                textAlign="center"
                fontWeight="bold"
                margin={[16, 0, 0, 0]}
              >
                Have immediate questions? Call us!
              </Typography>
              <Typography textAlign="center" fontSize="1.7rem" fontWeight="bold">
                <a style={{ color: "black" }} href="tel:18665802280">1-866-580-2280</a>
              </Typography>
            </span>
          </Content>
        </Col>
      </Row>
    </Container>
  );
}
